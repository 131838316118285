var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-timeline-item',{class:[
    'cy-event-timeline-item pb-0',
    { 'cy-event-timeline-item--force-show-opposite': _vm.forceShowOpposite } ],attrs:{"role":"listitem","aria-label":"Event","right":"","small":"","icon":_vm.getEventsIcon(_vm.event),"color":null},scopedSlots:_vm._u([{key:"opposite",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRelativeTimestamp),expression:"showRelativeTimestamp"}],class:[
        'cy-event-timestamp cy-event-timestamp--relative',
        { 'line-height-md': _vm.forceShowOpposite } ],attrs:{"data-cy":"timestamp-relative"}},[_vm._v(" "+_vm._s(_vm.formatTimeAgo(_vm.event.timestamp))+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAbsoluteTimestamp),expression:"showAbsoluteTimestamp"}],staticClass:"cy-event-timestamp cy-event-timestamp--absolute",attrs:{"data-cy":"timestamp-absolute"}},[_c('div',[_vm._v(" "+_vm._s(_vm.formatTimestamp(_vm.event.timestamp).day)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.formatTimestamp(_vm.event.timestamp).time)+" ")])])]},proxy:true}])},[(_vm.event.type === 'Cycloid')?_c('div',{attrs:{"data-cy":"message"},domProps:{"innerHTML":_vm._s(_vm.renderEventMessage(_vm.event))}}):[_c('div',{staticClass:"font-weight-bold pa-0",attrs:{"data-cy":"title"}},[_vm._v(" "+_vm._s(_vm.event.title)+" ")]),_c('v-expansion-panels',{staticClass:"cy-event-details mt-2"},[_c('v-expansion-panel',{staticClass:"elevation-1 rounded"},[_c('v-expansion-panel-header',{staticClass:"cy-event-details__header px-4 py-0",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var open = ref.open;
return [_c('div',{class:[{
                'text-truncate': !open,
                'py-3': open,
              }],attrs:{"data-cy":"heading"},domProps:{"innerHTML":_vm._s(_vm.renderEventMessage(_vm.event, 'heading'))}})]}}])}),_c('v-expansion-panel-content',{staticClass:"cy-event-details__content"},[_c('div',{attrs:{"data-cy":"body"},domProps:{"innerHTML":_vm._s(_vm.renderEventMessage(_vm.event, 'body'))}})])],1)],1)],_c('v-card-text',{staticClass:"pa-0 mt-2"},[_c('CyTagList',{ref:"tags",attrs:{"tags":_vm.getEventTags(_vm.event),"contained":"","small":"","sort-key":""},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
              var tag = ref.tag;
return [_vm._t("tag",null,null,{ tag: tag })]}}],null,true)})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }